import { Box, Container, Typography } from '@mui/material'
import MiniDrawer from '../components/MiniDrawer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useApi } from '../providers/ApiProvider'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { toast } from 'react-toastify'

const Content = () => {
  const { token } = useApi()

  const onCopy = () => {
    if (token) {
      navigator.clipboard.writeText(token)
      toast.success('Copied')
    }
  }

  return (
    <Container>
      <Typography variant="h4">Account</Typography>
      <Box sx={{ m: 2 }} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Details</TableCell>
              <TableCell align="right">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={'token'}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {'token'}
              </TableCell>
              <TableCell align="right">
                {token && (
                  <Box
                    sx={{
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}
                    display="flex"
                    justifyContent="right"
                    alignItems="center"
                    onClick={onCopy}
                  >
                    {token}
                    <Box sx={{ m: 1 }} />
                    <ContentCopyIcon />
                  </Box>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default function Account() {
  return <MiniDrawer children={<Content />} />
}
