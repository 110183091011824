import {
  Box,
  Button,
  Container,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import MiniDrawer from '../components/MiniDrawer'
import { useApi } from '../providers/ApiProvider'
import { toast } from 'react-toastify'
import Page from '../routes/Page'
import { LoadingButton } from '@mui/lab'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { PolicyModel } from '../models/PolicyModel'

const Content = () => {
  const { createLicense, getPolicies } = useApi()
  const [loading, setLoading] = useState(false)
  const [policyId, setPolicyId] = useState<string>('')
  const [policies, setPolicies] = useState<PolicyModel[]>([])
  const [expiry, setExpiry] = useState<Dayjs | null>(null)
  const refName = useRef<HTMLInputElement>(null)

  const onSubmit = async () => {
    try {
      const name = refName.current?.value
      if (!policyId) {
        toast.error('Policy is required')
        return
      }
      setLoading(true)
      const license = await createLicense(policyId, name, expiry?.toDate())
      setLoading(false)
      console.log(license)
      toast.success('License created')
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  const onSelectPolicy = (event: SelectChangeEvent) => {
    setPolicyId(event.target.value as string)
  }

  const onBack = () => {
    window.history.back()
  }

  const fetchPolicies = useCallback(async () => {
    try {
      const res = await getPolicies()
      setPolicies(res?.data || [])
    } catch (error) {
      toast.error(error.message)
    }
  }, [getPolicies])

  useEffect(() => {
    fetchPolicies()
  }, [fetchPolicies])

  return (
    <Container>
      <Typography variant="h4">{Page.createLicense.title}</Typography>
      <Box sx={{ m: 2 }} />
      <FormGroup>
        <FormControl variant="outlined">
          <InputLabel required id="label-select-policy">
            Policy
          </InputLabel>
          <Select
            labelId="label-select-policy"
            label="Policy"
            id="select-policy"
            value={policyId}
            onChange={onSelectPolicy}
          >
            {policies.map((policy) => (
              <MenuItem key={policy.id} value={policy.id}>
                {policy.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ m: 2 }} />

        <FormControl fullWidth variant="outlined">
          <TextField
            inputRef={refName}
            id="name"
            label="Name"
            variant="outlined"
          />
        </FormControl>
        <Box sx={{ m: 2 }} />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Expiry Date"
            value={expiry}
            onChange={(newValue: any) => setExpiry(newValue)}
          />
        </LocalizationProvider>
        <Box sx={{ m: 2 }} />

        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => onSubmit()}
        >
          Submit
        </LoadingButton>
        <Box sx={{ m: 2 }} />
        <Button variant="contained" color="secondary" onClick={() => onBack()}>
          Back
        </Button>
      </FormGroup>
    </Container>
  )
}

export default function CreateLicense() {
  return <MiniDrawer children={<Content />} />
}
