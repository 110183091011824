import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography
} from '@mui/material'
import MiniDrawer from '../components/MiniDrawer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useApi } from '../providers/ApiProvider'
import { useEffect, useState, useCallback } from 'react'
import { LicenseModel } from '../models/LicenseModel'
import Page from '../routes/Page'
import { toast } from 'react-toastify'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const Content = () => {
  const { getLicenses } = useApi()
  const [licenses, setLicenses] = useState<LicenseModel[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const fetchLicenses = useCallback(async () => {
    setLoading(true)
    const res = await getLicenses()
    setLicenses(res?.data || [])
    setLoading(false)
  }, [getLicenses])

  const onCreate = () => {
    window.location.href = Page.createLicense.path
  }

  const onSelectLicense = (id: string) => {
    window.location.href = Page.licenseDetails.path.replace(':id', id)
  }

  const onCopy = (key: string) => {
    navigator.clipboard.writeText(key)
    toast.success('License copied')
  }

  useEffect(() => {
    fetchLicenses()
  }, [fetchLicenses])

  return (
    <Container>
      <Typography variant="h4">Licenses</Typography>
      <Box sx={{ m: 2 }} />
      <Button variant="contained" onClick={() => onCreate()}>
        Create
      </Button>
      {loading && (
        <div className="center">
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right">License Key</TableCell>
                <TableCell align="right">Expiry</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Max Machines</TableCell>
                <TableCell align="right">Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {licenses.map((license) => (
                <TableRow
                  key={license.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#f5f5f5'
                    }
                  }}
                  onClick={() => onSelectLicense(license.id)}
                >
                  <TableCell component="th" scope="row">
                    {license.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {license.attributes.name}
                  </TableCell>
                  <TableCell align="right" sx={{ maxWidth: 300 }}>
                    <Box
                      sx={{
                        '&:hover': {
                          cursor: 'pointer'
                        }
                      }}
                      display="flex"
                      justifyContent="right"
                      alignItems="center"
                      onClick={() => onCopy(license.attributes.key)}
                    >
                      <Typography
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {license.attributes.key}
                      </Typography>
                      <Box sx={{ m: 1 }} />
                      <ContentCopyIcon />
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {license.attributes.expiry}
                  </TableCell>
                  <TableCell align="right">
                    {license.attributes.status}
                  </TableCell>
                  <TableCell align="right">
                    {license.attributes.maxMachines}
                  </TableCell>
                  <TableCell align="right">
                    {license.attributes.created}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  )
}

export default function Licenses() {
  return <MiniDrawer children={<Content />} />
}
