import { Box, Button, CircularProgress } from '@mui/material'
import { useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { useApi } from '../providers/ApiProvider'
// import { TEST_QR_CODE } from '../utils/Mock'
import { toast } from 'react-toastify'
import MiniDrawer from '../components/MiniDrawer'

const Content = () => {
  const [result, setResult] = useState<string | null>(null)
  const { validate, activate, checkout } = useApi()
  const [loading, setLoading] = useState<boolean>(false)

  const onClickDownload = async () => {
    // const result = TEST_QR_CODE
    try {
      if (result) {
        setLoading(true)
        const resValidate = await validate(result)
        console.log(resValidate)

        const resActivate = await activate(
          resValidate?.licenseKey || '',
          resValidate?.licenseId || '',
          resValidate?.machineFingerprint || ''
        )
        console.log(resActivate)

        const resCheckout = await checkout(
          resActivate?.machineId || '',
          resValidate?.machineFingerprint || '',
          resValidate?.licenseKey || ''
        )
        console.log(resCheckout)

        setLoading(false)

        const certificate = resCheckout?.certificate

        if (!certificate) {
          toast.error('No certificate')
          return
        }

        const link = document.createElement('a')
        const url = window.URL.createObjectURL(
          new Blob([certificate], { type: 'application/octet-stream' })
        )
        link.href = url
        link.setAttribute('download', `cert.lic`)
        link.click()
      }
    } catch (e) {
      console.error(e)
      toast.error('Error')
    }
  }

  return (
    <div className="container">
      <h1>License QR Code Scanner</h1>
      <p>Scan the QR showing on the machine's settings page</p>
      <QrReader
        onResult={(result, error) => {
          if (result) {
            setResult(result.getText())
          }
          if (error) {
            console.info(error)
          }
        }}
        constraints={{ facingMode: 'environment' }}
        containerStyle={{ width: '90%', margin: 'auto' }}
      />
      <Box mt={2} />
      <Box>
        {loading && <CircularProgress />}
        {!loading && result && (
          <Button variant="contained" onClick={onClickDownload}>
            Download
          </Button>
        )}
      </Box>
    </div>
  )
}

export default function Scanner() {
  return <MiniDrawer children={<Content />} />
}
