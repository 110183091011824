import { CircularProgress } from '@mui/material'
import ErrorBoundary from '../pages/ErrorBoundary'
import { useAuth } from '../providers/AuthProvider'
import Page from './Page'

type ProtectedRouteProps = {
  children: JSX.Element
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isAuthenticated, isAuthenticating, error } = useAuth()
  if (!isAuthenticated && !isAuthenticating) {
    // redirect to login page
    console.log('redirect to login page')
    window.location.href = Page.login.path
    return <></>
  }

  if (isAuthenticating) {
    return (
      <div className="center">
        <CircularProgress />
      </div>
    )
  }

  if (error) {
    console.error(error)
    return <ErrorBoundary />
  }

  return children
}

export default ProtectedRoute
