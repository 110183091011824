import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Typography
} from '@mui/material'
import MiniDrawer from '../components/MiniDrawer'
import { useApi } from '../providers/ApiProvider'
import { toast } from 'react-toastify'
import Page from '../routes/Page'
import { LoadingButton } from '@mui/lab'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

interface Props {
  id: string
}

const Content = ({ id }: Props) => {
  const { getLicense, updateLicense, deleteLicense } = useApi()
  const [loading, setLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const refName = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)
  const [isFetched, setIsFetched] = useState(false)
  const [expiry, setExpiry] = useState<Dayjs | null>(null)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const fetchLicense = useCallback(async () => {
    try {
      setIsFetched(false)
      const license = await getLicense(id)
      setIsFetched(true)
      if (license && refName.current) {
        refName.current.value = license.data.attributes.name || ''
      }
      if (license && license.data.attributes.expiry) {
        // convert to dayjs
        const date = dayjs(license.data.attributes.expiry)
        setExpiry(date)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }, [getLicense, id])

  const onSubmit = async () => {
    try {
      const name = refName.current?.value.trim()
      if (!name) {
        toast.error('Name is required')
        return
      }
      setLoading(true)
      const license = await updateLicense(id, name, expiry?.toDate())
      setLoading(false)
      console.log(license)
      toast.success('License updated')
      onBack()
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  const onDelete = async () => {
    try {
      setIsDeleting(true)
      await deleteLicense(id)
      setLoading(false)
      toast.success('License deleted')
      onBack()
    } catch (error) {
      setIsDeleting(false)
      toast.error(error.message)
    }
  }

  const onBack = () => {
    window.location.href = Page.licenses.path
  }

  useEffect(() => {
    fetchLicense()
  }, [fetchLicense])

  return (
    <Container>
      <Typography variant="h4">{Page.licenseDetails.title}</Typography>
      <Box sx={{ m: 2 }} />
      {!isFetched && (
        <div className="center">
          <CircularProgress />
        </div>
      )}
      {isFetched && (
        <>
          <FormControl fullWidth variant="outlined">
            <TextField
              inputRef={refName}
              required
              id="name"
              label="Name"
              variant="outlined"
              defaultValue={' '}
            />
            <Box sx={{ m: 2 }} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Expiry Date"
                defaultValue={expiry}
                onChange={(newValue: any) => setExpiry(newValue)}
              />
            </LocalizationProvider>
            <Box sx={{ m: 2 }} />
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={() => onSubmit()}
            >
              Submit
            </LoadingButton>
            <Box sx={{ m: 1 }} />
            <LoadingButton
              variant="contained"
              color="error"
              loading={isDeleting}
              onClick={handleClickOpen}
            >
              Delete
            </LoadingButton>
            <Box sx={{ m: 1 }} />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onBack()}
            >
              Back
            </Button>
          </FormControl>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Warning: Delete License'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this license?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={onDelete} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  )
}

export default function LicenseDetails(props: Props) {
  return <MiniDrawer children={<Content id={props.id} />} />
}
