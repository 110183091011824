import { Box, CircularProgress, Container, Typography } from '@mui/material'
import MiniDrawer from '../components/MiniDrawer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useApi } from '../providers/ApiProvider'
import { useEffect, useState, useCallback } from 'react'
import { MachineModel } from '../models/MachineModel'

const Content = () => {
  const { getMachines } = useApi()
  const [machines, setMachines] = useState<MachineModel[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const fetchMachines = useCallback(async () => {
    setLoading(true)
    const res = await getMachines()
    setMachines(res?.data || [])
    setLoading(false)
  }, [getMachines])

  useEffect(() => {
    fetchMachines()
  }, [fetchMachines])

  return (
    <Container>
      <Typography variant="h4">Machines</Typography>
      <Box sx={{ m: 2 }} />
      {loading && (
        <div className="center">
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell align="right">Fingerprint</TableCell>
                <TableCell align="right">Last CheckOut</TableCell>
                <TableCell align="right">Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {machines.map((machine) => (
                <TableRow
                  key={machine.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {machine.id}
                  </TableCell>
                  <TableCell align="right">
                    {machine.attributes.fingerprint}
                  </TableCell>
                  <TableCell align="right">
                    {machine.attributes.lastCheckOut}
                  </TableCell>
                  <TableCell align="right">
                    {machine.attributes.created}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  )
}

export default function Machines() {
  return <MiniDrawer children={<Content />} />
}
