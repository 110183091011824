import { Typography } from '@mui/material'
import MiniDrawer from '../components/MiniDrawer'

const Content = () => {
  return (
    <>
      <Typography variant="h4">Licensing Solution Hierarchy</Typography>
      <Typography variant="body1">
        Products (group by clients and devices)
      </Typography>
      <Typography variant="body1">- Policies (apply to products)</Typography>
      <Typography variant="body1">-- Licenses (create by policies)</Typography>
      <Typography variant="body1">
        -- Machines (binding licenses to devices)
      </Typography>
    </>
  )
}

export default function Home() {
  return <MiniDrawer children={<Content />} />
}
