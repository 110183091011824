import { useEffect } from 'react'
import { useAuth } from '../providers/AuthProvider'
import Page from '../routes/Page'

export default function Index() {
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = Page.home.path
    }
  }, [isAuthenticated])

  return <></>
}
