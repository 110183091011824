import {
  Box,
  Button,
  Container,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import MiniDrawer from '../components/MiniDrawer'
import { useApi } from '../providers/ApiProvider'
import { toast } from 'react-toastify'
import Page from '../routes/Page'
import { LoadingButton } from '@mui/lab'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ProductModel } from '../models/ProductModel'

const Content = () => {
  const { createPolicy, getProducts } = useApi()
  const [productId, setProductId] = useState<string>('')
  const [products, setProducts] = useState<ProductModel[]>([])
  const [loading, setLoading] = useState(false)
  const refName = useRef<HTMLInputElement>(null)
  const [duration, setDuration] = useState('')

  const durationOptions = [
    { value: 0, label: 'Unlimited' },
    { value: 86400, label: '1 Day' },
    { value: 172800, label: '2 Days' },
    { value: 259200, label: '3 Days' },
    { value: 345600, label: '4 Days' },
    { value: 432000, label: '5 Days' },
    { value: 604800, label: '1 Week' },
    { value: 1209600, label: '2 Weeks' },
    { value: 1814400, label: '3 Weeks' },
    { value: 2419200, label: '4 Weeks' },
    { value: 3024000, label: '5 Weeks' },
    { value: 2592000, label: '1 Month' },
    { value: 5184000, label: '2 Months' },
    { value: 7776000, label: '3 Months' },
    { value: 10368000, label: '4 Months' },
    { value: 12960000, label: '5 Months' },
    { value: 15552000, label: '6 Months' },
    { value: 23328000, label: '9 Months' },
    { value: 34186698, label: '13 Months' },
    { value: 31536000, label: '1 Year' },
    { value: 63072000, label: '2 Years' },
    { value: 94608000, label: '3 Years' }
  ]

  const fetchProducts = useCallback(async () => {
    try {
      const res = await getProducts()
      setProducts(res?.data || [])
    } catch (error) {
      toast.error(error.message)
    }
  }, [getProducts])

  const onSubmit = async () => {
    try {
      const name = refName.current?.value
      if (!name) {
        toast.error('Name is required')
        return
      }
      if (!productId) {
        toast.error('Product is required')
        return
      }
      setLoading(true)
      const policy = await createPolicy(name, productId)
      setLoading(false)
      console.log(policy)
      toast.success('Policy created')
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  const onSelectDuration = (event: SelectChangeEvent) => {
    setDuration(event.target.value as string)
  }

  const onSelectProduct = (event: SelectChangeEvent) => {
    setProductId(event.target.value as string)
  }

  const onBack = () => {
    window.history.back()
  }

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  return (
    <Container>
      <Typography variant="h4">{Page.createPolicy.title}</Typography>
      <Box sx={{ m: 2 }} />
      <FormGroup>
        <TextField
          inputRef={refName}
          required
          id="name"
          label="Name"
          variant="outlined"
        />
        <Box sx={{ m: 2 }} />

        <FormControl variant="outlined">
          <InputLabel id="label-select-duration">Duration</InputLabel>
          <Select
            labelId="label-select-duration"
            label="Duration"
            id="select-duration"
            value={duration}
            onChange={onSelectDuration}
          >
            {durationOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ m: 2 }} />

        <FormControl variant="outlined">
          <InputLabel id="label-select-product">Product</InputLabel>
          <Select
            labelId="label-select-product"
            label="Product"
            id="select-product"
            value={productId}
            onChange={onSelectProduct}
          >
            {products.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ m: 2 }} />

        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => onSubmit()}
        >
          Submit
        </LoadingButton>
        <Box sx={{ m: 2 }} />
        <Button variant="contained" color="secondary" onClick={() => onBack()}>
          Back
        </Button>
      </FormGroup>
    </Container>
  )
}

export default function CreatePolicy() {
  return <MiniDrawer children={<Content />} />
}
