import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'

// Define the authentication context
interface AuthContextType {
  isAuthenticated: boolean
  login: (email: string, password: string) => boolean
  logout: () => void
  isAuthenticating: boolean
  error: Error | null
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

interface Props {
  children: ReactNode
}

// Create the AuthProvider component
const AuthProvider: React.FC<Props> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const login = (email: string, password: string): boolean => {
    // validate the email and password
    const adminEmail = process.env.REACT_APP_ADMIN_EMAIL
    const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD
    if (email === adminEmail && password === adminPassword) {
      localStorage.setItem('isAuthenticated', 'true')
      setIsAuthenticated(true)
      setError(null)
      return true
    }
    setError(null)
    return false
  }

  const logout = () => {
    localStorage.removeItem('isAuthenticated')
    localStorage.removeItem('token')
    setError(null)
    setIsAuthenticated(false)
  }

  const authContextValue: AuthContextType = {
    isAuthenticated,
    login,
    logout,
    isAuthenticating,
    error
  }

  useEffect(() => {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem('isAuthenticated')
    if (isAuthenticated) {
      setIsAuthenticated(true)
    }
    setIsAuthenticating(false)
  }, [])

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  )
}

// Custom hook to use the AuthContext
const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
