import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Typography
} from '@mui/material'
import MiniDrawer from '../components/MiniDrawer'
import { useApi } from '../providers/ApiProvider'
import { toast } from 'react-toastify'
import Page from '../routes/Page'
import { LoadingButton } from '@mui/lab'
import { useCallback, useEffect, useRef, useState } from 'react'

interface Props {
  id: string
}

const Content = ({ id }: Props) => {
  const { getProduct, updateProduct, deleteProduct } = useApi()
  const [loading, setLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const refName = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const fetchProduct = useCallback(async () => {
    try {
      const product = await getProduct(id)
      if (product) {
        refName.current!.value = product.data.attributes.name
      }
    } catch (error) {
      toast.error(error.message)
    }
  }, [getProduct, id])

  const onSubmit = async () => {
    try {
      const name = refName.current?.value.trim()
      if (!name) {
        toast.error('Name is required')
        return
      }
      setLoading(true)
      const product = await updateProduct(id, name)
      setLoading(false)
      console.log(product)
      toast.success('Product updated')
      onBack()
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  const onDelete = async () => {
    try {
      setIsDeleting(true)
      await deleteProduct(id)
      setLoading(false)
      toast.success('Product deleted')
      onBack()
    } catch (error) {
      setIsDeleting(false)
      toast.error(error.message)
    }
  }

  const onBack = () => {
    window.location.href = Page.products.path
  }

  useEffect(() => {
    fetchProduct()
  }, [fetchProduct])

  return (
    <Container>
      <Typography variant="h4">{Page.productDetails.title}</Typography>
      <Box sx={{ m: 2 }} />
      <FormControl fullWidth variant="outlined">
        <TextField
          inputRef={refName}
          required
          id="name"
          label="Name"
          variant="outlined"
          defaultValue={' '}
        />
        <Box sx={{ m: 2 }} />
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => onSubmit()}
        >
          Submit
        </LoadingButton>
        <Box sx={{ m: 1 }} />
        <LoadingButton
          variant="contained"
          color="error"
          loading={isDeleting}
          onClick={handleClickOpen}
        >
          Delete
        </LoadingButton>
        <Box sx={{ m: 1 }} />
        <Button variant="contained" color="secondary" onClick={() => onBack()}>
          Back
        </Button>
      </FormControl>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Warning: Delete Product'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default function ProductDetails(props: Props) {
  return <MiniDrawer children={<Content id={props.id} />} />
}
