import HomeIcon from '@mui/icons-material/Home'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import KeyIcon from '@mui/icons-material/Key'
import PolicyIcon from '@mui/icons-material/Policy'
import ComputerIcon from '@mui/icons-material/Computer'
import DevicesIcon from '@mui/icons-material/Devices'

export interface PageItem {
  title: string
  path: string
  icon: any
}

const Page = {
  index: { title: '', path: '/' } as PageItem,
  home: { title: 'Home', path: '/home', icon: HomeIcon } as PageItem,
  error: { title: 'Error', path: '/error' } as PageItem,
  login: { title: 'Login', path: '/login' } as PageItem,
  scanner: {
    title: 'Scanner',
    path: '/scanner',
    icon: QrCodeScannerIcon
  } as PageItem,
  products: {
    title: 'Products',
    path: '/products',
    icon: ComputerIcon
  } as PageItem,
  policies: {
    title: 'Policies',
    path: '/policies',
    icon: PolicyIcon
  } as PageItem,
  licenses: {
    title: 'Licenses',
    path: '/licenses',
    icon: KeyIcon
  } as PageItem,
  machines: {
    title: 'Machines',
    path: '/machines',
    icon: DevicesIcon
  } as PageItem,
  account: {
    title: 'Account',
    path: '/account',
    icon: AccountBoxIcon
  } as PageItem,
  logout: {
    title: 'Logout',
    path: '/logout',
    icon: LogoutIcon
  } as PageItem,
  createProduct: {
    title: 'Create Product',
    path: '/products/new'
  } as PageItem,
  productDetails: {
    title: 'Product Details',
    path: '/products/:id'
  } as PageItem,
  createPolicy: {
    title: 'Create Policy',
    path: '/policies/new'
  } as PageItem,
  policyDetails: {
    title: 'Policy Details',
    path: '/policies/:id'
  } as PageItem,
  createLicense: {
    title: 'Create License',
    path: '/licenses/new'
  } as PageItem,
  licenseDetails: {
    title: 'License Details',
    path: '/licenses/:id'
  } as PageItem
}

export const MenuItems = [
  Page.home,
  Page.scanner,
  Page.products,
  Page.policies,
  Page.licenses,
  Page.machines,
  Page.account,
  Page.logout
]

export default Page
